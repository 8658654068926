App.Modules = App.Modules || {};
App.Modules.ScrollToMap = function () {
  // these are option items, just a simple way to
  // keep yourself organized...
  var o = { };

  function scrollWindow() {
    var scroll = new SmoothScroll('.js', {header: '#header'});
    setTimeout(
      function () {
        var map = $('#map-div')[0];
        scroll.animateScroll(map);
        console.log('scrolling');
      },
      1000
    );
  }

  function checkMapLoaded()
  {
    if( window.HYPE )
    {
      scrollWindow();
    } else {
      setTimeout(function () {
        checkMapLoaded();
      }, 100);
    }
  }

  return {
     init: function() {
       if ( /\#map$/.test(window.location.href) ) {
         checkMapLoaded();
       }
       return this;
     },
     events: function() {
        return this;
     }
  };
}();

