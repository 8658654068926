App.Modules = App.Modules || {};
App.Modules.Search = function () {
   // these are option items, just a simple way to
   // keep yourself organized...
   var o = { };


   var searchOpen = function(data) {
   var search = data.eventElement.parentNode;
   // var icon = data.eventElement.querySelector('.fa');

    if (search.classList.contains("open")) {
         
         search.classList.remove("open");

      } else {
         
         search.classList.add("open");
         $('input#q').focus();

      }

   };

   return {
      init: function() { return this; },
      events: function() {

         // standard call to a click event
         Events.bind("click", "#search-trigger").to(searchOpen, {context: this});

         return this;
      }
   };
}();
