App.Modules = App.Modules || {};
App.Modules.Awards = function () {
   // these are option items, just a simple way to
   // keep yourself organized...
   var o = { };

   var awardToggle = function(data) {
      
      var award = $( data.eventElement );
      var awardList = $( data.eventElement.nextElementSibling );
      
      // close currently open award if user is clicking on same element
      if (award.hasClass("open")) {
         
          awardList.removeClass("open").slideUp(400, function() {
            award.removeClass("open")
          });

      } else {

         if( $('.awards-list').is(':visible') ) {
            $('.awards-list').slideUp(300);
            $('.award-header').removeClass('open');
            award.removeClass('open');
         }

         // open a new awards list -- make sure it scrolls into view
         // css padding and negative margin used to compensate for sticky header
         awardList.addClass("open").slideDown(600, function() {
            award.addClass("open").fadeIn(400); 
            award.get(0).scrollIntoView({
               behavior: 'smooth',
               block: 'start'
            });
         });

      }

   };

   return {
      init: function() { 
         // always show the first two years and make sure they have the right classes
         $('.awards .awards-list:first,.awards .awards-list:first').show().addClass('open');
         $('.awards .award-header:first,.awards .award-header:first').addClass('open');
         return this; 
      },
      events: function() {

         // standard call to a click event
         Events.bind("click", ".award-header").to(awardToggle, {context: this});

         return this;
      }
   };
}();
