App.Modules = App.Modules || {};
App.Modules.mobilenav = function () {

  // toggle the header to full height or not
  toggleHeader = function(data) 
  {

    if ( $('ul.mobile.nav').hasClass('open') ) {
      $('.header').css({'min-height':'40%'});
    } else {
      $('.header').css({'min-height':'0'});
      $('ul.mobile.nav').removeClass('open');
    }

  }

  // show and hide the mobile menu
  mobileMenu = function(data)
  {

      if ( $('ul.mobile.nav').hasClass('open') ) {

        $('body').removeClass('no-scroll');
        $('ul.mobile.nav, .mobile-nav-wrap').css({'max-height':0});
        $('ul.mobile.nav').removeClass('open');
        $('#mobile-nav-toggle').html('Menu');

        closeSearch();
        
      } else {
        
        $('body').addClass('no-scroll');

        $('.mobile-nav-wrap').css({'max-height':'999px'});
        $('ul.mobile.nav').css({
            'max-height': $(window).height(),
            'overflow-y' : 'auto'
        });
        $('ul.mobile.nav').addClass('open');
        $('#mobile-nav-toggle').html('Close');
      }

      toggleHeader();

  };

  // toggles the search bar in mobile navigation header
  // changes search icon to close icon to allow close
  searchToggle = function() 
  {
      if ( ! $('.mobile-search .search').hasClass('open') ) {
        $('.mobile-search, .mobile-search .search').addClass('open').css({'max-height':'150px'});
        $('#mobile-search-toggle').removeClass('fa-search').addClass('fa-close');
        $('input#q').focus();

        if ( ! $('ul.mobile.nav').hasClass('open') ) {
          $('.mobile-nav-wrap').css({'max-height':'150px'})
        }
      } else {
        closeSearch();
      }
  };

  closeSearch = function(data)
  {
    $('.mobile-search .search, .mobile-search').removeClass('open').css({'max-height':'0'});
    $('#mobile-search-toggle').removeClass('fa-close').addClass('fa-search');
  }

  // second level in mobile navigation is expandable
  submenuExpand = function(data)
  {
    var sublist = $( data.eventElement );
    var next = sublist.parent().next('ul.nav');
    console.log(sublist);

    if ( next.height() === 0 ) {
      sublist.css({
        'transform':'rotate(90deg)',
        'margin-top':'-10px'
      });
      next.animate({'max-height':'500px'});
    } else {
      sublist.css({'transform':'rotate(0)'});
      next.animate({'max-height':'0'});
    }
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click','#mobile-nav-toggle').to(mobileMenu, this);
      Events.bind('click','#mobile-search-toggle').to(searchToggle, this);
      Events.bind('click','.mobile.nav .fa-caret-right').to(submenuExpand, this);

      return this;
    }
  };
}();

