App.Modules = App.Modules || {};
App.Modules.Credit = function () {
   // these are option items, just a simple way to
   // keep yourself organized...
   var o = { };

   var creditToggle = function(data) {
   var credit = $(data.eventElement).parent();
   var icon = $(data.eventElement).parent().children('.fa');

   console.log($(data.eventElement).parent());

    if (credit.hasClass("open")) {
         
         credit.removeClass("open");
         icon.addClass("fa-plus-square-o");
         icon.removeClass("fa-minus-square-o");

      } else {
         
         credit.addClass("open");
         icon.addClass("fa-minus-square-o");
         icon.removeClass("fa-plus-square-o");
      }

   };

   return {
      init: function() { return this; },
      events: function() {

         // standard call to a click event
         Events.bind("click", ".credit .toggle").to(creditToggle, {context: this});

         return this;
      }
   };
}();
