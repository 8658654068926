App.Modules = App.Modules || {};
App.Modules.HomepagePanels = function () {
  // these are option items, just a simple way to
  // keep yourself organized...

    var container = $('.panel-wrapper:not(.no-slide)'),
      $dots,
      $panels,
      activePanelIndex = 0,
      numPanels,
      isAnimating = false,
      footerVisible = false,

    changePanel = function(data)
    {
      if(! isAnimating ) {
        var delta = data.event.deltaY;
        if (delta > 0) {
          nextPanel();
        } else if (delta < 0) {
          prevPanel();
        }
      }
    },

    changePanelClick = function(data)
    {
      var $btn = $(data.eventElement);
      goToPanel($btn.index());
    },

    changePanelKeypress = function(data)
    {
      if( ! isAnimating ) {
        data.event.preventDefault();
        switch (data.eventKey)
        {
          case 32:
            nextPanel();
            break;
          case 38:
            prevPanel();
            break;
          case 40:
            nextPanel();
            break;
          default:
            break;
        }
      }
    },

    checkFooter = function()
    {
      // SHOW THE FOOTER
      if( $('.home .panel:last-of-type').hasClass('active') && ! footerVisible ) {
        $('.home .panel:last-of-type').addClass('footer-revealed'); //css({bottom: '154px'});
        isAnimating = true;
        footerVisible = true;

        var t = setTimeout(function () {
          isAnimating = false;
        }, 1000);

      } else {
        $('.home .panel:last-of-type').removeClass('footer-revealed'); //css({bottom: '-139px'});
        isAnimating = true;
        footerVisible = false;

        var t = setTimeout(function () {
          isAnimating = false;
        }, 1000);
      }
    },

    nextPanel = function()
    {
      goToPanel(activePanelIndex + 1);
      //if (activePanelIndex < numPanels - 1 && ! isAnimating) {
      //  isAnimating = true;
      //  var $curPanel = $panels.eq(activePanelIndex);
      //  $curPanel.addClass('hidden').removeClass('active');
      //  $curPanel.next('.panel').addClass('active');
      //
      //  //navigation dots
      //  var $curDot = $dots.eq(activePanelIndex);
      //  $dots.removeClass('active');
      //  $curDot.next('.dot').addClass('active');
      //
      //  var t = setTimeout(function () {
      //    $curPanel.addClass('offscreen');
      //    isAnimating = false;
      //    activePanelIndex++;
      //    //checkFooter();
      //  }, 1500);
      //} else if(! isAnimating) {
      //  checkFooter();
      //}
    },

    prevPanel = function()
    {
      goToPanel(activePanelIndex - 1);
      //if( activePanelIndex > 0 && ! isAnimating) {
      //  if( footerVisible )
      //  {
      //    checkFooter();
      //  } else {
      //    isAnimating = true;
      //    var $prevPanel = $panels.eq(activePanelIndex - 1),
      //      $curPanel = $panels.eq(activePanelIndex);
      //    $prevPanel.removeClass('offscreen').removeClass('hidden').addClass('active');
      //    $curPanel.removeClass('active');
      //
      //    //navigation dots
      //    var $curDot = $dots.eq(activePanelIndex);
      //    $dots.removeClass('active');
      //    $curDot.prev('.dot').addClass('active');
      //
      //    var t = setTimeout(function () {
      //      isAnimating = false;
      //      activePanelIndex--;
      //    }, 1500);
      //  }
      //
      //  //checkFooter();
      //}
    },

    goToPanel = function(newPanelIndex)
    {
      if( ! isAnimating && newPanelIndex >= 0 && newPanelIndex <= numPanels - 1 && newPanelIndex !== activePanelIndex )
      {

        if( footerVisible )
        {
          checkFooter();
        } else {
          isAnimating = true;

          var $curPanel = $panels.eq(activePanelIndex),
            $newPanel = $panels.eq(newPanelIndex);

          if( newPanelIndex > activePanelIndex )
          {
            for(var i = (activePanelIndex + 1); i < newPanelIndex; i++)
            {
              $panels.eq(i).addClass('offscreen').addClass('hidden');
            }
            $curPanel.addClass('hidden').removeClass('active');
            $newPanel.removeClass('hidden').removeClass('offscreen').addClass('active');
          }

          if( newPanelIndex < activePanelIndex )
          {
            for(var i = (activePanelIndex + 1); i < newPanelIndex; i++)
            {
              $panels.eq(i).addClass('offscreen').addClass('hidden');
            }
            $curPanel.removeClass('active');
            $newPanel.removeClass('offscreen').removeClass('hidden').addClass('active');
          }

          //navigation dots
          $dots.removeClass('active');
          $dots.eq(newPanelIndex).addClass('active');

          var t = setTimeout(function () {
            isAnimating = false;
            if( newPanelIndex > activePanelIndex )
            {
              $curPanel.addClass('offscreen');
            }
            activePanelIndex = newPanelIndex;
          }, 1500);
        }

      } else {
        checkFooter();
      }
    };

  return {
    init: function() {
      $panels = $('.panel');
      numPanels = $panels.length;
      $dots = $('.dot');
      
      return this;
    },
    events: function() {

      if ( $( window ).width() >= 1160 && $('body').hasClass('home') ) {

        Events.bind(
          "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
          document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
            "DOMMouseScroll", '.home .panel-wrapper, .designs .panel-wrapper'
        ).to(changePanel, {context: this});

        Events.bind('click', '.panel-dots .dot').to(changePanelClick, {context: this});
        Events.bind('keydown').to(changePanelKeypress, {context: this});
        
      }

      return this;
    }
  };
}();

