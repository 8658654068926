App.Modules = App.Modules || {};
App.Modules.ResizeLayout = function () {

 var adjustLayout = function()
 {

  var $footer = $('footer'),
    $header = $('header'),
    $wrapper = $('#wrapper'),
    $panelWrapper = $('.panel-wrapper:not(.no-slide)'),
    $main = $('#main');

    $wrapper.css({'marginBottom': $footer.outerHeight(true) - 1 });
    $main.css({'paddingTop': $header.outerHeight(true) - 2 });

    if( $( window ).width() >= 1160 ) {
      $panelWrapper.css({'height': "calc(100vh - " + ( $header.outerHeight(true) - 2 ) + "px)"});
    }

    // homepage 'scrollbar'
    $('.panel-switcher .switch .prev-panel').css({'paddingTop': $header.outerHeight(true) - 2 });

 },

 shrinkMenu = function(data)
  {

    var headerHeight = $('header').outerHeight(true);

    if( ! $('body').hasClass('no-scroll') ) {

      if (parseInt(data.event.deltaY, 10) !== 0) {
        adjustLayout();
      }
    }

  };

  return {
    init: function() {
      adjustLayout();
      return this;
    },
    events: function() {
      Events.bind('resize').to(adjustLayout.debounce(250), {context: this});

      Events.bind(
        "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
          document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
            "DOMMouseScroll"
      ).to(shrinkMenu, {context: this, prevent: false});

      return this;
    }
  };
}();

