// i stole this from AD
// https://bitbucket.org/jkd/adhq/src/06ca944f0e5e87ebf6eb670d60314eb6727ef748/src/js/modules/full-screen-video.js?at=master&fileviewer=file-view-default

App.Modules = App.Modules || {};
App.Modules.FullScreenVideo = function () {

  var openVideo = function(data) {

    $('body').toggleClass('no-scroll');

    var iFrameVideo =
      '<iframe' +
      ' src="//www.youtube.com/embed/' + $(data.eventElement).attr( 'data-video' ) +
      '?rel=0' +
      '&autoplay=1' +
      '&showinfo=0' +
      '&modestbranding=1' +
      '&theme=light' +
      ' frameborder="0"' +
      ' allowfullscreen>' +
      '</iframe>';

    $('.js-video-embed-container').css({
      'background-color': '#000'
    }).fadeIn(250);
    $('.js-video-embed-container .contain').html(iFrameVideo);
    $('.js-video-embed-container').addClass('open').fadeIn(500); 
  };

  var closeVideo = function() {
    $('.js-video-embed-container').removeClass('open').fadeOut(400, function() {
      $('.js-video-embed-container .contain').html('');
      $('body').toggleClass('no-scroll');
    });
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '[data-video]').to(openVideo, this);
      Events.bind('click', '.close-video').to(closeVideo, this);
      return this;
    }
  };
}();
